import { round } from 'helpers/math'

/**
 * Convert pixels to rem using root font size
 * @param size
 * @param fontSize
 * @returns {string}
 */
const convertPxToRem = (size, fontSize = 16) => `${round(size / fontSize, 5)}rem`

export default convertPxToRem
