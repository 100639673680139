import { toRem } from 'helpers/theme'

/**
 * Convert pixels to rem using root font size
 * @param values
 * @returns {array}
 */
const convertPxToRems = values => values.map(value => toRem(value)).join(' ')

export default convertPxToRems
