/* eslint-disable import/no-absolute-path, import/extensions, quote-props */
import { colors } from 'theme/globals'

export const defaults = {
    fontFamily: {
        primary: 'Poppins',
        secondary: 'Poppins'
    },
    fontDisplay: 'swap',
    fontSize: 18,
    fontSizes: {
        // [type]: [xs, sm, md, lg, xl, xxl]
        h1: [40, 40, 40, 48, 56, 72],
        h2: [28, 28, 32, 32, 40, 48],
        h3: [26, 26, 28, 28, 36, 44],
        h4: [24, 24, 24, 24, 24, 24],
        p: [16, 16, 18, 18, 18, 20],
        small: [14, 14, 14, 14, 14, 14]
    },
    fontStyle: 'normal',
    lineHeight: 1,
    fontWeight: {
        extraLight: 200,
        light: 300,
        regular: 400,
        medium: 500,
        bold: 700,
        black: 900
    }
}

const rendering = {
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
    textRendering: 'optimizeLegibility'
}

const fontSettings = {
    html: {
        ...rendering,
        fontFamily: defaults.fontFamily.primary,
        fontSize: '100%'
    },
    /**
     * Enter any CSS properties in breakpoints size order ASC, i.e.
     *     { fontSize 16, letterSpacing: 0 }, // xs
     *     { fontSize 18, letterSpacing: 0.5 }, // sm
     *     { ... }, // md
     *     { ... }, // lg
     *     { ... }, // xl
     *     { ... }, // xxl
     */
    'h1, h2, h3, h4': {
        fontFamily: defaults.fontFamily.secondary,
        fontWeight: defaults.fontWeight.medium,
        color: colors.headings.default
    },
    p: {
        fontFamily: defaults.fontFamily.primary,
        fontWeight: defaults.fontWeight.regular
    },
    small: {
        fontFamily: defaults.fontFamily.primary,
        fontWeight: defaults.fontWeight.regular
    },
    a: {
        color: colors.text.link,
        // Remove gray backgrounds on active links in IE10
        background: 'transparent',
        // Remove gaps in links undrline in iOS 8+ and Safari 8+
        textDecorationSkip: 'objects',
        textDecoration: 'none',
        '&:hover': {
            color: colors.text.link
        },
        // Remove the outline on focused links when they are also hovered or active
        '&:active, &:hover': {
            color: colors.text.link,
            outlineWidth: 0
        }
    },
    button: {
        fontFamily: defaults.fontFamily.primary
    },
    'ol, ul': {
        padding: '0',
        listStylePosition: 'inside',
        '& li': {
            letterSpacing: 0.4,
            lineHeight: 1.4
        }
    }
}

export default {
    ...fontSettings,
    font: {
        primary: defaults.fontFamily.primary,
        secondary: defaults.fontFamily.secondary,
        display: defaults.fontDisplay,
        weight: defaults.fontWeight,
        style: defaults.fontStyle
    },
    fontSizes: defaults.fontSizes,
    fontWeight: defaults.fontWeight,
    rootFontSize: defaults.fontSize
}
