/* eslint-disable import/no-absolute-path, import/extensions, quote-props, max-len */
export default {
    containerWidth: {
        lg: '90vw',
        xl: '80vw',
        xxl: '70vw'
    },
    sectionContainerWidth: {
        lg: '90vw',
        xl: '80vw',
        xxl: '60vw'
    }
}
