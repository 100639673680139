// import { convertHexToRGB as rgba } from 'helpers/theme'

// Colors must not be used directly in components, but they should be (re)assigned here instead
const palette = {
    white: '#FFFFFF',
    black: '#000000',
    blueDark: '#001B28',
    blueMid: '#002131',
    blueMidAlt: '#00354F',
    blueLight: '#0E425B',
    goldLight: '#F9CC6D',
    goldDark: '#E3B05F',
    goldDarker: '#D4A459',
    blackLighter: '#031C2D',
    blackDarker: '#031621',
    gold: '#E9C579',
    red: '#D32929',
    gray: '#AFAFAF'
}

// Configure basic global typography colors here
const colors = {
    text: {
        default: palette.white,
        blueMid: palette.blueMid,
        goldLight: palette.goldLight,
        goldDark: palette.goldDark
    },
    headings: {
        default: palette.white,
        blueMid: palette.blueMid,
        goldLight: palette.goldLight,
        goldDark: palette.goldDark
    },
    link: {
        underline: {
            default: palette.greyLight,
            hover: palette.gold
        },
        social: {
            default: palette.black,
            white: palette.white,
            light: palette.greyDark,
            pop: palette.teal,
            gold: palette.gold
        }
    },
    nav: {
        text: {
            default: palette.black,
            hover: palette.teal,
            light: palette.white
        },
        underline: palette.gold,
        sub: {
            mobile: palette.greyDark,
            background: palette.greyLight
        },
        background: palette.greyLight
    },
    btn: {
        primary: {
            text: palette.white,
            shadow: palette.teal,
            border: {
                default: palette.teal,
                hover: palette.teal
            },
            background: {
                default: palette.teal,
                hover: palette.tealLight
            }
        },
        secondary: {
            text: palette.teal,
            shadow: palette.teal,
            border: {
                default: palette.teal,
                hover: palette.white
            },
            background: {
                hover: palette.white
            }
        }
    },
    shadow: {
        hero: palette.blue,
        menu: palette.blue
    },
    headerFill: {
        default: palette.black,
        white: palette.white,
        light: palette.greyDark,
        pop: palette.teal
    },
    border: {
        default: palette.black,
        white: palette.white,
        light: palette.greyDark,
        pop: palette.teal,
        popLight: palette.tealLighter,
        gold: palette.gold
    },
    background: {
        white: palette.white,
        grey: palette.grey,
        greyLight: palette.greyLighter,
        black: palette.black,
        pureBlack: palette.pureBlack,
        goldDark: palette.goldDark
    },
    input: {
        label: palette.black,
        field: {
            text: palette.black,
            background: palette.pureWhite,
            border: palette.pureWhite,
            placeholder: palette.greyDark,
            shadow: palette.blue,
            focus: {
                border: palette.tealLight
            },
            error: {
                text: palette.red,
                border: palette.red
            },
            disabled: {
                text: palette.greyDark,
                background: palette.greyDark
            }
        }
    }
}

export default {
    ...palette,
    ...colors
}
