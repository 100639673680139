import { toRem, responsive } from 'helpers/theme'

const createFontVariations = ({ fontSizes = {}, fontSize = 16 }) => {
    const responsiveVariants = { ...fontSizes }
    Object.keys(responsiveVariants).forEach(type => {
        responsiveVariants[type] = responsiveVariants[type].map(size => ({ fontSize: toRem(size, fontSize) }))
    })

    Object.keys(responsiveVariants).forEach(type => {
        responsiveVariants[type] = {
            ...responsive(responsiveVariants[type])
        }
    })

    return responsiveVariants
}

export default createFontVariations
