// We need to centralize the zIndex definitions as they work like global values in the browser.
export default {
    under: '-1',
    over: 1,
    tooltip: 20,
    transitionOverlay: 25,
    header: 30,
    modal: 100,
    gridOverlay: 110,
    cookieNotice: 120,
    mobileNav: 1000 // rule them all
}
