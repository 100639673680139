const NextI18Next = require('next-i18next').default
const { lngPathCorrector } = require('next-i18next/dist/commonjs/utils')

const AVAILABLE_DOMAINS = {
    'merkandmerk.com': 'en',
    'merkandmerk.de': 'de',

    'merken.preview.human.hr': 'en',
    'merkde.preview.human.hr': 'de',

    // for testing while developing
    'merkde.localhost:9999': 'de',
    'merken.localhost:9999': 'en'
}

const languageByDomainDetector = {
    name: 'domain',
    lookup(req) {
        let language = 'en'
        if (typeof window !== 'undefined') {
            language = AVAILABLE_DOMAINS[window.location.hostname]
        } else {
            language = AVAILABLE_DOMAINS[req.get('host')]
        }

        return language
    },
    cacheUserLanguage(lng, options) {}
}

/** @type {import('next-i18next').InitConfig} */
const options = {
    defaultLanguage: 'en',
    ignoreRoutes: ['/_next/', '/public'],
    localePath: 'public/static/locales',
    defaultNS: 'common',
    otherLanguages: ['de'],
    detection: {
        order: ['domain']
    },
    customDetectors: [languageByDomainDetector],
    browserLanguageDetection: true,
    serverLanguageDetection: true
}

const i18next = new NextI18Next(options)

module.exports = i18next
// eslint-disable-next-line import/no-commonjs
module.exports.lngPathCorrector = lngPathCorrector
